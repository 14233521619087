// eslint-disable-next-line eslint-comments/disable-enable-pair
/* eslint-disable jsx-a11y/control-has-associated-label */
import React, { useRef, useEffect } from 'react';
import { useLocation } from '@reach/router';
import { graphql } from 'gatsby';
import { nanoid } from 'nanoid';
import Icon from '@brighthr/component-icon';
import Container from '../components/Container';
import DefaultLayout from '../layouts/Default';
import ProfilePanel from '../components/CMSBlocks/ProfilePanel';
import SEO from '../components/SEO';
import StockImage from '../images/stock-office-hero.png';
import Hero from '../components/CMSBlocks/Hero';
import QuoteBlock from '../components/CMSBlocks/QuoteBlock';
import FlexibleMetricsPanel from '../components/CMSBlocks/FlexibleMetricsPanel';
import FilterButtons from '../components/FilterButtons';
import useFilter from '../hooks/useFilter';

const WhatWeDo = ({ data }) => {
	const allCompanies = data.allDatoCmsCompany.nodes;
	const { heroTitle, heroCopyNode, overviewElements } = data.datoCmsWhatWeDo;

	const ref = useRef();

	const { updateFilter, changeFilter, filteredEntries, filters, parsedEntries, resetFilters } =
		useFilter({
			allEntries: allCompanies,
			multiOption: 'services',
			ref,
		});

	const groupedCompanies = filteredEntries.reduce((acc, obj) => {
		const key = obj.groupCompany;
		if (!acc[key]) {
			acc[key] = {
				// adding all the company key values
				groupCompany: obj.groupCompany,
				excerpt: obj.excerpt,
				services: obj.services,
				logo: obj.logo,
				country: obj.country,
				doNotShowCompaniesPage: obj.doNotShowCompaniesPage,
				fullDescriptionNode: obj.fullDescriptionNode,
				websites: [],
			};
		}
		// listing each locale website only once
		if (!acc[key].websites.includes(obj.website)) {
			acc[key].websites.push(obj.website);
		}
		return acc;
	}, {});

	useEffect(() => {
		ref.current.scrollLeft += -10000;
	}, [filters]);

	const location = useLocation();

	return (
		<>
			<SEO path={location.pathname} title="What we do" />
			<Hero
				bgImg={StockImage}
				title="What we do"
				description={heroTitle}
				content={heroCopyNode.childMdx.body}
			/>
			<section className="flex flex-col px-4 mt-10 md:pl-5 lg:pr-5 md:mt-20 md:flex-row md:max-w-6xl md:mx-auto">
				<div className="md:w-1/2 md:basis-1/2">
					{overviewElements.map((element) => (
						<React.Fragment key={element.id}>
							{element.internal.type === 'DatoCmsFlexibleMetricsPanel' && (
								<FlexibleMetricsPanel
									title={element.title}
									metrics={element.metrics}
								/>
							)}
						</React.Fragment>
					))}
				</div>
				<div className="pt-5 md:w-1/2 md:basis-1/2">
					{overviewElements.map((element) => (
						<React.Fragment key={element.id}>
							{element.internal.type === 'DatoCmsQuoteWithHeadshot' && (
								<QuoteBlock
									quoteText={element.quoteTextNode}
									person={element.person}
									vertical
								/>
							)}
						</React.Fragment>
					))}
				</div>
			</section>
			<section>
				<Container className="!px-0 md:px-unset">
					<div className="px-4 mt-10 mb-8 text-center md:mt-24 md:mb-16">
						<h2 className="w-full text-2xl font-normal text-center md:text-3xl ">
							Our Companies and Services
						</h2>
						<p className="block text-black">
							Discover more about the companies that make up the Peninsula Group
							below.
						</p>
					</div>
					<div className="relative flex flex-col-reverse lg:flex-row brighthr">
						<div className="w-full lg:w-3/4 md:pt-0">
							<div
								ref={ref}
								className="flex flex-row w-full pt-2 pb-4 my-3 overflow-x-scroll overflow-y-hidden md:flex-col md:pb-8 md:overflow-x-auto md:my-0 md:py-0 md:gap-y-4 snap-mandatory snap-x scroll-smooth"
							>
								{Object.keys(groupedCompanies).map((key) => (
									<div key={nanoid()}>
										<ProfilePanel
											info={groupedCompanies[key]}
											companies={groupedCompanies}
											className="mx-2 border-2 md:mx-0 snap-center group border-secondary-100 md:border-t-0 md:border-l-0 md:border-r-0 md:border-b-3 "
										/>
									</div>
								))}
							</div>
							{filteredEntries?.length === 0 && (
								<div className="flex flex-col items-center justify-center mb-8">
									<h2 className="text-xl md:text-2xl">No results..</h2>
									<p>Please search again</p>
								</div>
							)}
							<div className="flex justify-center px-4 md:hidden">
								<button
									type="button"
									// eslint-disable-next-line no-return-assign
									onClick={() => (ref.current.scrollLeft += -300)}
									className="p-2 mx-2 bg-white rounded-full shadow-xl"
								>
									<Icon
										size={32}
										className="fill-secondary-300 active:fill-brand-100 focus:active:fill-brand-100"
										iconName="chevron-thin-left"
									/>
								</button>
								<button
									type="button"
									// eslint-disable-next-line no-return-assign
									onClick={() => (ref.current.scrollLeft += 300)}
									className="p-2 mx-2 bg-white rounded-full shadow-xl"
								>
									<Icon
										size={32}
										className="fill-secondary-300 active:fill-brand-100 focus:active:fill-brand-100"
										iconName="chevron-thin-right"
									/>
								</button>
							</div>
						</div>
						<aside className="w-full px-4 py-0 bg-white lg:w-1/4 md:shadow-none md:h-fit md:px-8 md:my-8 md:mt-2 md:overflow-hidden top-16 md:top-20">
							<div className="z-30 flex flex-col-reverse pb-2 pointer-events-auto lg:flex-col md:bg-transparent">
								<FilterButtons
									className=" lg:block lg:my-4"
									changeFilter={changeFilter}
									filters={filters}
									allEntries={parsedEntries}
									updateFilter={updateFilter}
									resetFilters={resetFilters}
								/>
							</div>
						</aside>
					</div>
				</Container>
			</section>
		</>
	);
};

export const query = graphql`
	query WhatWeDo {
		allDatoCmsCompany(sort: { fields: position, order: ASC }) {
			nodes {
				...DatoCMSCompanyFragment
			}
		}
		datoCmsWhatWeDo {
			heroTitle
			heroCopyNode {
				childMdx {
					body
				}
			}
			overviewElements {
				... on DatoCmsQuoteWithHeadshot {
					id
					quoteTextNode {
						childMdx {
							body
						}
					}
					person {
						company
						companyPositionFullTitle
						personName
						personImage {
							alt
							gatsbyImageData
						}
						personBio
						personBioNode {
							childMdx {
								body
							}
						}
						personTwitter
						personTwitterHandle
					}
					internal {
						type
					}
				}
				... on DatoCmsFlexibleMetricsPanel {
					id
					title
					internal {
						type
					}
					metrics {
						id
						metric
						metricAppend
						metricPrepend
						metricCopy
						metricCopyNode {
							childMdx {
								body
							}
						}
						internal {
							type
						}
					}
				}
			}
		}
	}
	fragment DatoCMSCompanyFragment on DatoCmsCompany {
		fullDescriptionNode {
			childMdx {
				body
			}
		}
		groupCompany
		excerpt
		country
		companyName
		doNotShowCompaniesPage
		mapIcon {
			gatsbyImageData
		}
		longitude
		lattitude
		logo {
			gatsbyImageData(layout: CONSTRAINED, height: 50, imgixParams: { auto: "compress" })
		}
		services
		website
	}
`;

const WrappedWhatWeDo = (props) => (
	<DefaultLayout fullWidth>
		<WhatWeDo {...props} />
	</DefaultLayout>
);

export default WrappedWhatWeDo;
