import React from 'react';
import { GatsbyImage } from 'gatsby-plugin-image';
import { nanoid } from 'nanoid';
import CopyBlock from '../../CopyBlock';

import UKFlag from '../../../images/vectors/uk-flag.svg';
import IEFlag from '../../../images/vectors/ireland-flag.svg';
import CAFlag from '../../../images/vectors/canada-flag.svg';
import AUFlag from '../../../images/vectors/au-flag.svg';
import NZFlag from '../../../images/vectors/nz-flag.svg';
import ExternalLink from '../../../images/vectors/external-link.svg';

const ProfilePanel = ({ info, className }) => {
	const listOfWebsites = {
		'https://www.peninsulagrouplimited.com/': 'Peninsula UK',
		'https://www.peninsulagrouplimited.com/ie/': 'Peninsula IE',
		'https://peninsulacanada.com/': 'Peninsula CA',
		'https://www.brighthr.com/': 'BrightHR UK',
		'https://www.brighthr.com/ie/': 'BrightHR IE',
		'https://www.brighthr.com/ca/': 'BrightHR CA',
		'https://www.brighthr.com/au/': 'BrightHR AU',
		'https://www.brighthr.com/nz/': 'BrightHR NZ',
		'https://peninsulagrouplimited.com.au/': 'Peninsula AU',
		'https://peninsulagrouplimited.co.nz/': 'Peninsula NZ',
		'https://www.graphitehrm.com/': 'Graphite IE',
		'https://www.irwell-law.com/': 'Irwell UK',
		'https://www.healthassured.org/': 'Health Assured UK',
		'https://www.healthassured.org/ie/': 'Health Assured IE',
		'https://www.croneri.co.uk/': 'Croner-I UK',
		'https://croner.co.uk/': 'Croner UK',

		// company urls from "Companies" section
	};

	return (
		<div className={` py-4  ${className}`}>
			<div className="w-[75vw] flex flex-col  h-full md:flex-row md:w-[unset] relative">
				<div className="flex flex-col self-start w-full p-3 space-y-3 lg:p-2 md:w-1/4 md:space-y-0">
					<div>
						<GatsbyImage
							image={info.logo?.gatsbyImageData}
							alt={info.logo?.alt || ''}
							loading="lazy"
							objectFit="contain"
							className="mr-0 md:mr-8"
						/>
					</div>
					<div>
						<div className="md:hidden">{info.excerpt}</div>
					</div>
					<div className="md:absolute md:bottom-0">
						{info.websites.map((website) => (
							<div className="flex my-1 md:my-3 justify-items-between" key={nanoid()}>
								{(website.includes('/ie') || website.includes('graphite')) && (
									<img
										src={IEFlag}
										className="inline-block mr-2"
										alt="Country flag"
									/>
								)}
								{(website.includes('/ca') ||
									website.includes('.ca') ||
									website.includes('canada')) && (
									<img
										src={CAFlag}
										className="inline-block mr-2"
										alt="Country flag"
									/>
								)}
								{(website.includes('/au') || website.includes('.au')) && (
									<img
										src={AUFlag}
										className="inline-block mr-2"
										alt="Country flag"
									/>
								)}
								{(website.includes('/nz') || website.includes('.nz')) && (
									<img
										src={NZFlag}
										className="inline-block mr-2"
										alt="Country flag"
									/>
								)}

								{!website.includes('/ie') &&
									!website.includes('graphite') &&
									!website.includes('/ca') &&
									!website.includes('.ca') &&
									!website.includes('canada') &&
									!website.includes('/au') &&
									!website.includes('.au') &&
									!website.includes('/nz') &&
									!website.includes('.nz') && (
										<img
											src={UKFlag}
											className="inline-block mr-2"
											alt="Country flag"
										/>
									)}
								<a
									className="block text-brand-200"
									href={website}
									target="_blank"
									key={nanoid()}
									rel="noreferrer"
								>
									{listOfWebsites[website]}

									<img
										src={ExternalLink}
										alt="external link"
										className="inline-block w-4 mx-2 mb-1"
									/>
								</a>
							</div>
						))}
					</div>
				</div>

				<div className="hidden md:block md:w-3/4">
					<div className="flex flex-col justify-center p-3 md:p-0 md:items-start ">
						<CopyBlock>{info?.fullDescriptionNode.childMdx.body}</CopyBlock>
					</div>
					{info.services && (
						<div className=" md:mt-4">
							<h3 className="mb-4">Services:</h3>
							<ul className="flex flex-row flex-wrap list-disc list-inside">
								{info.services.map((service) => (
									<li
										className="p-2 pl-3 mb-2 mr-2 text-xs leading-none rounded-full bg-secondary-100"
										key={nanoid()}
									>
										{service}
									</li>
								))}
							</ul>
						</div>
					)}
				</div>
			</div>
		</div>
	);
};

export default ProfilePanel;
