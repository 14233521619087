import React from 'react';
import CopyBlock from '../../CopyBlock';
import Ticker from '../../Ticker';

const FlexibleMetricsPanel = ({ title, metrics }) => (
	<section className="md:pr-36">
		<h2 className="mb-16 text-4xl font-normal">{title}</h2>
		{metrics.map((element) => {
			if (element.internal.type === 'DatoCmsMetricBlock') {
				return (
					<div className="mb-10" key={element.id}>
						<div className="mb-2">
							{element.metricPrepend && (
								<span className="text-5xl">{element.metricPrepend}</span>
							)}
							<Ticker className="text-5xl" end={element.metric} />
							{element.metricAppend && (
								<span className="text-5xl">{element.metricAppend}</span>
							)}
						</div>
						{element.metricCopy && (
							<div>
								<CopyBlock>{element.metricCopyNode.childMdx.body}</CopyBlock>
							</div>
						)}
					</div>
				);
			}
			return '';
		})}
	</section>
);

export default FlexibleMetricsPanel;
